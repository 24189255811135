import * as React from "react";
// import Hero from "../components/marketing/3dHero";
import Hero from "../components/marketing/hero";
import Contact from "../components/contact";
import Layout from "../components/layout";
import BizzMegMinket from "../components/marketing/bizzMegMinket";
import EgyuttmukodesiFormak from "../components/marketing/egyuttmukodesiFormak";
import Folyamat from "../components/marketing/folyamat";
import Velemenyek from "../components/marketing/velemenyek";
import IsmerjMegMinket from "../components/marketing/ismerjMegMinket";
import VelukDolgoztunk from "../components/marketing/velukDolgoztunk";
import Parallax from "../components/marketing/parallax";

export const Marketing: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Marketing"
      description="Hozzuk ki a vállalkozásodból a maximumot. Mi irányt mutatunk és végig támogatunk a közösen kijelölt úton."
    >
      <Hero />
      <BizzMegMinket />
      <EgyuttmukodesiFormak />
      <Folyamat />
      <Velemenyek />
      <Parallax />
      <IsmerjMegMinket />
      <VelukDolgoztunk />
      <Contact variant="marketing" />
    </Layout>
  );
};

export default Marketing;
