import React from "react";
import { Container } from "../../styles/ui";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import Marquee from "react-fast-marquee";

const VelukDolgoztunk: React.FC = () => {
  return (
    <Wrapper>
      <TitleContainer>
        <h2>Velük dolgoztunk együtt</h2>
        <h1>
          Akik már <span>bizalmat szavaztak</span>
        </h1>
      </TitleContainer>
      <StyledMarquee gradient={true} speed={40}>
        <StaticImage
          alt={"Magyar Vitorlás Szövetség"}
          src={"../../images/referenciak/mvszlogokicsi.jpg"}
          height={100}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"DORKO"}
          src={"../../images/referenciak/drk.png"}
          height={90}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"Honda Tóth"}
          src={"../../images/referenciak/honda.png"}
          height={80}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"L'Occitane en Provence"}
          src={"../../images/referenciak/loccitane.svg"}
          height={70}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"FASTRRR"}
          src={"../../images/referenciak/fastrrr.png"}
          height={80}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"Császár Dental"}
          src={"../../images/referenciak/csaszardental.png"}
          height={90}
          style={{ marginRight: "100px" }}
        />
        {/* MOAR */}
        <StaticImage
          alt={"Magyar Vitorlás Szövetség"}
          src={"../../images/referenciak/mvszlogokicsi.jpg"}
          height={100}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"DORKO"}
          src={"../../images/referenciak/drk.png"}
          height={90}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"Honda Tóth"}
          src={"../../images/referenciak/honda.png"}
          height={80}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"L'Occitane en Provence"}
          src={"../../images/referenciak/loccitane.svg"}
          height={70}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"FASTRRR"}
          src={"../../images/referenciak/fastrrr.png"}
          height={80}
          style={{ marginRight: "100px" }}
        />
        <StaticImage
          alt={"Császár Dental"}
          src={"../../images/referenciak/csaszardental.png"}
          height={90}
          style={{ marginRight: "100px" }}
        />
      </StyledMarquee>
    </Wrapper>
  );
};

export default VelukDolgoztunk;

const Wrapper = styled.section`
  /* overflow: hidden; */
  background: white;
  position: relative;
  width: 100%;
  padding-bottom: 96px;
`;

const TitleContainer = styled(Container)`
  background: white;
  padding-top: 128px;
  padding-bottom: 128px;
  text-align: center;
  h1 {
    margin-top: 0;
  }
  h2 {
    font-weight: 400;
  }

  @media (max-width: 640px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const StyledMarquee = styled(Marquee)`
  /* overflow: hidden; */
  position: relative;
  width: 100%;
  height: 200px;

  img {
    filter: grayscale(100%);
    transition: opacity 250ms linear, filter 250ms ease-in-out !important; // gatsbby image opacity overwrite
    &:hover {
      filter: grayscale(0%);
      outline: none;
      border: none;
    }
  }
`;
