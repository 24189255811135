import React from "react";
import { Container } from "../../styles/ui";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper";
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);
import useMediaQuery from "@mui/material/useMediaQuery";

const Velemenyek: React.FC = () => {
  const single = useMediaQuery("(max-width:900px)");
  const withNav = useMediaQuery("(min-width:500px)");

  return (
    <Wrapper>
      <TitleContainer>
        <h2>Mit mondanak rólunk?</h2>
        <h1>
          Ügyfeleink <span>véleménye</span>
        </h1>
      </TitleContainer>
      <Container>
        <Swiper
          slidesPerView={single ? 1 : 2}
          spaceBetween={64}
          navigation={withNav}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <Opinion>
              <Profile>
                <StaticImage
                  src="../../images/referenciak/stacey.jpg"
                  alt="Profilkép"
                  objectFit="contain"
                />
              </Profile>
              <h3>Szigeti Stacey</h3>
              <p>Fastrrr Kft.</p>
              <br />
              <p>
                &ldquo;Örülök, hogy a Compass Marketing csapatát választottuk!
                Pontos, gyors munkavégzés, profi hozzáállás jellemezte a közös
                munkánkat. Nagyon szeretünk együtt dolgozni, hiszen rögtön
                megértette a csapat, hogy mire van szükségünk. Tiszta szívből
                ajánlom őket bárkinek!&rdquo;
              </p>
            </Opinion>
          </SwiperSlide>
          <SwiperSlide>
            <Opinion>
              <Profile>
                <StaticImage
                  src="../../images/referenciak/zita.jpg"
                  alt="Profilkép"
                  objectFit="contain"
                />
              </Profile>
              <h3>Dr. Császár Zita</h3>
              <p>Császár Dental</p>
              <br />
              <p>
                &ldquo;Nagyon alaposak és kedvesek! Mindent meg tudunk beszélni,
                amit nagyon szeretek!&rdquo;
              </p>
            </Opinion>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Opinion>
              <Profile>
                <StaticImage
                  src="../../images/referenciak/tothpeter.jpg"
                  alt="Profilkép"
                  objectFit="contain"
                />
              </Profile>
              <h3>Tóth Péter</h3>
              <p>Tóth Autó</p>
              <br />
              <p>
                &ldquo;A magas minőséget amit várok, teljes mértékben
                megkapom!&rdquo;
              </p>
            </Opinion>
          </SwiperSlide>
        </Swiper>
      </Container>
    </Wrapper>
  );
};

export default Velemenyek;

const Wrapper = styled.section`
  background: white;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 96px;

  .swiper {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;
    overflow: visible;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    position: relative;
    height: auto;

    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    text-align: center;
    font-size: 18px;
    /* background: #fcd; */
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TitleContainer = styled(Container)`
  padding-top: 128px;
  padding-bottom: 128px;
  text-align: center;
  h1 {
    margin-top: 0;
  }
  h2 {
    font-weight: 400;
  }
`;

const Opinion = styled.div`
  margin: 0 64px;
  max-width: 600px;
  height: 100%;
  box-shadow: var(--shadow);
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 32px 32px 32px;

  @media (max-width: 499px) {
    margin: 0;
  }
`;

const Profile = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 999px;
  border: 4px solid var(--color-primary);
  overflow: hidden;
`;
