import React, { useState, useEffect } from "react";

// import { a as three } from "@react-spring/three";
import { useSpring } from "@react-spring/core";
import { a as web } from "@react-spring/web";

import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import ShiftBy from "../../lib/shiftBy";

import { H1, PrimaryButton } from "../../styles/ui";
import HeroHullam from "../../images/svg/hero-hullam.svg";
import HeroBg from "../../images/svg/hero-bg.svg";

import useMediaQuery from "@mui/material/useMediaQuery";

import useScrollTo from "../../lib/scrollto";

const Hero: React.FC = () => {
  const canvasRef = React.useRef();
  const { scrollTo } = useScrollTo();
  const vertical = useMediaQuery("(max-width:1050px)");

  // macbook hinge
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  // ! CANNOT USE CSS VARIABLES INSIDE HERE
  const springConfig = { mass: 1, tension: 280, friction: 100 };
  const common = {
    borderRadius: "20px",
    overflow: "hidden",
    boxShadow:
      "0.4px 0.8px 1.7px rgba(0, 0, 0, 0.022),1px 2px 4px rgba(0, 0, 0, 0.032),1.9px 3.8px 7.5px rgba(0, 0, 0, 0.04),3.4px 6.7px 13.4px rgba(0, 0, 0, 0.048),6.3px 12.5px 25.1px rgba(0, 0, 0, 0.058),15px 30px 60px rgba(0, 0, 0, 0.08)",
    transform: "rotate(0deg) scale(0) translate(-50%,-50%)",
    opacity: 0,
    top: "50%",
    left: "50%",
    width: "0%",
    config: springConfig,
  };
  const [ch1style, ch1api] = useSpring(() => ({ ...common }));
  const [ch2style, ch2api] = useSpring(() => ({ ...common }));

  // start animations when model is loaded
  useEffect(() => {
    if (!open) return;
    const animChart1 = setTimeout(() => {
      ch1api.start({
        opacity: 1,
        top: "45%",
        left: "15%",
        width: "30%",
        transform: "rotate(-30deg) scale(0.7) translate(-50%,-50%)",
      });
    }, 1000);
    const animChart2 = setTimeout(() => {
      ch2api.start({
        opacity: 1,
        top: "20%",
        left: "55%",
        width: "30%",
        transform: "rotate(20deg) scale(0.7) translate(-50%,-50%) ",
      });
    }, 2000);
    return () => {
      clearTimeout(animChart1);
      clearTimeout(animChart2);
    };
  }, [open]);

  return (
    <>
      <Wrapper>
        <Left>
          <H1>
            Hozzuk ki a vállalkozásodból a <span>maximumot</span>
          </H1>
          <p>Mi irányt mutatunk és végig támogatunk a közösen kijelölt úton</p>
          <br />
          <PrimaryButton onClick={() => scrollTo("#contact")}>
            VEDD FEL VELÜNK A KAPCSOLATOT
          </PrimaryButton>
        </Left>
        <CanvasWrapper>
          <HeroHullam
            css={css`
              pointer-events: none;
              position: absolute;
              top: 10%;
              left: -50%;
              width: 150%;
              height: 60%;
            `}
          />
          {!vertical && (
            <HeroBg
              css={css`
                pointer-events: none;
                position: absolute;
                z-index: -1;
                top: 0;
                left: -50%;
                width: 200%;
                height: 100%;
              `}
            />
          )}
          <div
            css={css`
              pointer-events: none;
              position: absolute;
              /* z-index: -1; */
              top: 25%;
              left: 0;
              width: 100%;
              height: 75%;
            `}
          >
            <StaticImage
              src="../../images/hero-mac2.png"
              alt="laptop"
              objectFit="contain"
            />
          </div>
          <ChartWrapper>
            {/* <Chart> */}
            <Chart style={ch1style}>
              <StaticImage src="../../images/chart1.jpg" alt="chart1" />
            </Chart>
            {/* <Chart> */}
            <Chart style={ch2style}>
              <StaticImage src="../../images/chart2.jpg" alt="chart2" />
            </Chart>
          </ChartWrapper>
        </CanvasWrapper>
        <FollowUs>
          <a
            href="https://www.facebook.com/compassmarketing.agency2020/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillFacebook />
          </a>
          <a
            href="https://www.instagram.com/compassmarketing.agency"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram />
          </a>
          <div />
          <ShiftBy y={2}>
            <p>KÖVESS MINKET</p>
          </ShiftBy>
        </FollowUs>
      </Wrapper>
    </>
  );
};

export default Hero;

const Wrapper = styled.section`
  background: white;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "txt img";

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "img"
      "txt";
    height: auto;

    background: linear-gradient(
      180deg,
      #f0f0f0 0%,
      #fcfcfc 83.32%,
      #ffffff 100%
    );
  }
`;

const Left = styled.div`
  grid-area: txt;
  padding: 100px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1050px) {
    height: auto;
    max-width: 1920px;
    padding: 100px var(--gutter);
    margin: auto;
  }
`;

const CanvasWrapper = styled.div`
  grid-area: img;
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 1050px) {
    height: 800px; // fallback
    height: 60vw;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 60px;
  }
`;

const ChartWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;
const Chart = styled(web.div)`
  position: absolute;
  transform: translate(-50%, -50%);
`;

const FollowUs = styled.div`
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  right: 0;
  top: 50vh;
  /* background: papayawhip; */
  * {
    margin-right: 10px;
  }
  svg {
    width: 22px;
    height: 22px;
    transform: rotate(-90deg);
    fill: black;
  }
  div:first-of-type {
    width: 60px;
    height: 1px;
    background: black;
  }
  p {
    font-size: 1rem;
    color: black;
  }
  transform-origin: right top;
  transform: rotate(90deg) translate(50%, 100%);

  @media (max-width: 520px) {
    display: none;
  }
`;
