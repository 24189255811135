import React from "react";

import { Container, PrimaryButton } from "../../styles/ui";

import styled from "@emotion/styled";

import IsmerjMegMinketNyil from "../../images/svg/ismerjmegminket-nyil.svg";
import { StaticImage } from "gatsby-plugin-image";

import { Link } from "gatsby";

const IsmerjMegMinket: React.FC = () => {
  return (
    <Wrapper>
      <Div>
        <ImgContainer>
          <StaticImage
            src="../../images/ismerjmegminket.jpg"
            alt="Ismerj meg minket"
            objectFit="contain"
            imgStyle={{ borderRadius: "20px" }}
          />
        </ImgContainer>
        <TextContainer>
          <IsmerjMegMinketNyil />
          {/* <h2>Kik vagyunk mi?</h2> */}
          <h1>
            <span>Ismerj</span> meg minket
          </h1>
          <p>
            Csapatunk 2020-ban lépett a piacra azzal a céllal, hogy
            minőségorientált szolgáltatásunk révén kis-és közepes
            vállalkozásokat támogassunk a fejlődésükben. Arra törekszünk, hogy
            magas szinten képviseljük a szakmánkat, hosszú távú együttműködést
            alakítsunk ki, amelyhez szerintünk az ügyfeleink elégedettségén
            keresztül vezet az út.
          </p>
          <Link to="/marketing/rolunk">
            <PrimaryButton aria-label={"Ismerj meg minket"}>
              TÖBBET RÓLUNK
            </PrimaryButton>
          </Link>
        </TextContainer>
      </Div>
    </Wrapper>
  );
};

export default IsmerjMegMinket;

const Wrapper = styled.section`
  background: white;
  position: relative;
  width: 100%;
`;

const Div = styled(Container)`
  padding-top: 128px;
  padding-bottom: 128px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 64px;
  grid-template-areas: "img txt";

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "txt"
      "img";
  }
`;

const ImgContainer = styled.div`
  grid-area: img;
  position: relative;
  width: 80%;

  @media (max-width: 750px) {
    width: 100%;
  }
`;
const TextContainer = styled.div`
  grid-area: txt;
  position: relative;
  svg {
    width: 150px;
    fill: #000;
  }
  h1 {
    margin-top: 0;
  }
  h2 {
    font-weight: 400;
  }
  p {
    margin-bottom: 32px;
  }

  @media (max-width: 750px) {
    svg {
      display: none;
    }
  }
`;
