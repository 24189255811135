import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import { BoxGeometry } from "three";

const Parallax: React.FC = () => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  useEffect(() => {
    // gsap.set("#prlximg", { yPercent: -60 });
    gsap.set("#prlximg", { yPercent: 0 });
    const uncover = gsap.timeline({ paused: true });
    uncover.to("#prlximg", { yPercent: -100, ease: "none" });
    // uncover.to("#prlximg", { yPercent: 0, ease: "none" });
    ScrollTrigger.create({
      id: "imgprlx",
      trigger: "#prlximg",
      // trigger: "#marketing-parallax",
      start: "bottom bottom",
      // start: "top top",
      // start: "top bottom",
      // end: "bottom bottom",
      end: "+=500",
      animation: uncover,
      // scrub: true,
      invalidateOnRefresh: true,
      pin: true,
      // markers: true,
    });
    return () => {
      ScrollTrigger.getById("imgprlx")?.kill();
    };
  }, []);
  useEffect(() => {
    ScrollTrigger.refresh();
  }, [bounds]);

  return (
    <Wrapper>
      <BG>
        <StaticImage
          src="../../images/group3.jpg"
          alt="Csapat"
          objectFit="contain"
        />
      </BG>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "white",
          height: "10vw",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          background: "white",
          height: "10vw",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      ></div>
      {/* <div
        id="marketing-parallax"
        ref={ref}
        style={{ position: "relative", overflow: "hidden" }}
      >
        <div id="prlximg" style={{ position: "relative" }}>
          <StaticImage
            src="../../images/group3.jpg"
            alt="Csapat"
            objectFit="contain"
          />
        </div>
        <Upper />
        <Lower />
      </div> */}

      {/* <div
        style={{
          position: "relative",
          // zIndex: 22222,
          top: 0,
          left: 0,
          background: "#ccc",
          height: "25%",
          marginBottom: "25%",
        }}
      ></div>

      <div
        style={{
          // position: "absolute",
          top: 0,
          bottom: 0,
          background: "#ccc",
          height: "25%",
          marginTop: "25%",
        }}
      ></div> */}
    </Wrapper>
  );
};

export default Parallax;

// https://images.unsplash.com/photo-1523437113738-bbd3cc89fb19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80

const Wrapper = styled.section`
  /* transform: translate3d(0, 0, 0); */
  position: relative;
  width: 100%;
  height: 70vw;
  /* background: #fff0; */
  overflow: hidden;
  /* z-index: 1; */

  /* background-image: url("https://images.unsplash.com/photo-1523437113738-bbd3cc89fb19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
`;

const Upper = styled.div`
  background: white;
  position: absolute;
  /* position: relative; */
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
`;

const Lower = styled.div`
  background: white;
  position: absolute;
  /* position: relative; */
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
`;
const BG = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  > div {
    position: absolute;
    bottom: 0;
  }
`;
